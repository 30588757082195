import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import { CONFIG_BASE_DOMAIN } from '@/constants/config';

import { selectOrgUrl } from '../routes';
import { useTargetProperty } from './targetProperty';

/**
 * Verify that a `returnTo` url is a valid URL and is redirecting to the current
 * `baseDomain` or a subdomain of it.
 * @param returnTo a url to redirect to after successful login or register.
 * @returns if it is valid or not.
 */
function isValidReturnTo(returnTo?: string | null): returnTo is string {
  if (returnTo) {
    if (returnTo.startsWith('/')) {
      return true;
    }

    try {
      const url = new URL(returnTo);
      return url.host.endsWith(CONFIG_BASE_DOMAIN);
    } catch {
      return false;
    }
  }

  return false;
}

export function setReturnToStorage(value: string | undefined | null) {
  if (value) {
    sessionStorage.setItem('returnTo', value);
  } else {
    sessionStorage.removeItem('returnTo');
  }
}

function getReturnToStorage(): string | undefined | null {
  return typeof sessionStorage === 'object' ? sessionStorage.getItem('returnTo') : null;
}

export function useReturnTo(): [string | null | undefined, () => void] {
  // Clear the targetProperty when leaving as we won't need it anymore
  const [, clearTargetProperty] = useTargetProperty();
  const router = useRouter();
  const [returnTo, setReturnTo] = useState(getReturnToStorage());

  const performReturnTo = useCallback(() => {
    if (returnTo?.startsWith('/')) {
      router.push(returnTo).then(() => {
        setReturnTo(null);
      });
    } else {
      window.location.assign(returnTo ?? selectOrgUrl());
    }
    clearTargetProperty();
    setReturnToStorage(null);
  }, [clearTargetProperty, returnTo, router]);

  const returnToQP = (typeof router.query.returnTo === 'string' && router.query.returnTo) || undefined;
  const validReturnTo = isValidReturnTo(returnToQP);

  // This stores and removes the `returnTo=...` query parameter from the browser location bar if it exists.
  useEffect(() => {
    if (returnToQP) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { returnTo: _, ...routerQuery } = router.query;

      // Preserve existing query parameters if there are any.
      router.replace({ query: routerQuery }, undefined, { shallow: true });
    }

    if (validReturnTo) {
      setReturnToStorage(returnToQP);
      setReturnTo(returnToQP);
    }
  }, [returnToQP, router, validReturnTo]);
  return [validReturnTo ? returnToQP : returnTo, performReturnTo];
}
