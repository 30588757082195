import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

export enum TargetProperty {
  Unknown = 'unknown',
  Console = 'console',
  Marketplace = 'marketplace',
}

function decodeTargetProperty(targetProperty: string | undefined) {
  switch (targetProperty) {
    case 'console':
      return TargetProperty.Console;
    case 'marketplace':
      return TargetProperty.Marketplace;
    default:
      return TargetProperty.Unknown;
  }
}

function setTargetPropertyStorage(targetProperty: TargetProperty | undefined) {
  if (targetProperty) {
    sessionStorage.setItem('targetProperty', targetProperty);
  } else {
    sessionStorage.removeItem('targetProperty');
  }
}

function getTargetPropertyStorage(): TargetProperty {
  return decodeTargetProperty(
    typeof sessionStorage === 'object' ? sessionStorage.getItem('targetProperty') || undefined : undefined,
  );
}

/// Obtain target property from query param (`targetProperty=...`) or session storage
/// Return a tuple with the target property and a callback that can clear it (set to Unknown).
export function useTargetProperty(): [TargetProperty, () => void] {
  const router = useRouter();
  const [targetProperty, setTargetProperty] = useState(getTargetPropertyStorage());

  const targetPropertyQP =
    (typeof router.query.targetProperty === 'string' && router.query.targetProperty) || undefined;

  // This stores and removes the `targetProperty=...` query parameter from the browser location bar if it exists.
  useEffect(() => {
    if (targetPropertyQP) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { targetProperty, ...routerQuery } = router.query;

      // Preserve existing query parameters if there are any.
      router.replace({ query: routerQuery }, undefined, { shallow: true });

      const newTargetProperty = decodeTargetProperty(targetPropertyQP);
      setTargetPropertyStorage(newTargetProperty);
      setTargetProperty(newTargetProperty);
    }
  }, [targetPropertyQP, router, setTargetProperty]);

  const clearTargetProperty = useCallback(() => {
    const newTargetProperty = TargetProperty.Unknown;
    setTargetPropertyStorage(newTargetProperty);
    setTargetProperty(newTargetProperty);
  }, [setTargetProperty]);

  return [targetProperty, clearTargetProperty];
}
