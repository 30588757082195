import { FormikProps } from 'formik';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function DisableFormikButton<V = Array<any>>(
  { isSubmitting, isValid, dirty }: FormikProps<V>,
  checkDirty = true,
  isLoading?: boolean,
) {
  if (isLoading === undefined) {
    if (checkDirty === false) {
      return isSubmitting || !isValid;
    }

    return isSubmitting || !isValid || !dirty;
  }

  if (checkDirty === false) {
    return isLoading || isSubmitting || !isValid;
  }

  return isLoading || isSubmitting || !isValid || !dirty;
}
