import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';

import { COLORS, SHADES } from '@/constants/colors';
import * as constants from '@/constants/dialogs';
import { COLORS as COLORS_OLD, hexToRgba } from '@/constants/styles';
import { Box, Flex } from '@/elements/Div';
import { Paragraph } from '@/elements/Paragraph';

const dialogProps = (type: keyof typeof constants) => {
  switch (type) {
    case constants.DIALOG_ERROR:
      return {
        backgroundColor: SHADES.Red[50],
        borderColor: COLORS.Red[200],
      };
    case constants.DIALOG_SUCCESS:
      return {
        backgroundColor: COLORS_OLD.veryLightGreen,
        borderColor: COLORS_OLD.darkMint,
      };
    case constants.DIALOG_WARNING:
      return {
        backgroundColor: COLORS_OLD.palePeach,
        borderColor: COLORS_OLD.lightOrange,
      };
    case constants.DIALOG_INFO:
    default:
      return {
        backgroundColor: COLORS_OLD.paleSkyBlue,
        borderColor: COLORS_OLD.dodgerBlue,
      };
  }
};

const baseStyle = (type: keyof typeof constants) => css`
  width: 100%;
  border-radius: 5px;
  border: solid 1px ${dialogProps(type).borderColor};
  background-color: ${hexToRgba(dialogProps(type).backgroundColor, 0.2)};
  position: relative;
  display: flex;
  margin-bottom: 30px;
  padding-bottom: 20px;
  text-align: center;
`;

const Content = styled(Flex)`
  width: 100%;
  flex-direction: column;
  margin-top: 20px;
  padding: 0 15px;
`;

const fadeAnimation = keyframes`
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

export const DialogBox: React.FC<{
  animated?: boolean;
  children?: React.ReactNode;
  className?: string;
  msg?: string;
  /** Optional @timeout number in ms to wait before hiding DialogBox if animated is true */
  timeout?: number;
  title: string;
  type: keyof typeof constants;
}> = ({ animated, children, className, msg, timeout = 4500, title, type }) => {
  const [fadeProp, setFadeProp] = useState(false);

  useEffect(() => {
    setFadeProp(true);
  }, []);

  useEffect(() => {
    return () => {
      setFadeProp(false);
    };
  });

  const style = css`
    ${baseStyle(type)};
    ${className};
  `;

  const animatedStyle = css`
    ${style};
    animation: ${fadeAnimation} ${timeout + 500}ms;
  `;

  if (animated) {
    return (
      <Transition in={fadeProp} timeout={timeout} unmountOnExit={true} mountOnEnter={false}>
        <Box css={animatedStyle}>
          <Content>
            <Paragraph bold={true} m={0}>
              {title}
            </Paragraph>
            {msg && <Paragraph m="5px 0 0 0">{msg}</Paragraph>}
            {children}
          </Content>
        </Box>
      </Transition>
    );
  }

  return (
    <Box css={style}>
      <Content>
        <Paragraph bold={true} m={0}>
          {title}
        </Paragraph>
        {msg && <Paragraph m="5px 0 0 0">{msg}</Paragraph>}
        {children}
      </Content>
    </Box>
  );
};
