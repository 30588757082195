import { Field, Form, Formik } from 'formik';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { LoginValues } from '@/@types/models';
import { AuthBigButton, AuthFormInput, AuthLayout, AuthMainHeader, AuthStyledButton } from '@/components/AuthLayout';
import { DialogBox } from '@/components/DialogBox';
import FormDivider from '@/components/FormDivider';
import PageTitle from '@/components/PageTitle';
import { COLORS } from '@/constants/colors';
import { CONFIG_API_URL, CONFIG_ENTERPRISE_ENABLED } from '@/constants/config';
import * as dialogConstants from '@/constants/dialogs';
import { Link } from '@/elements/Anchor';
import { Box, Flex } from '@/elements/Div';
import { Paragraph } from '@/elements/Paragraph';
import * as api from '@/restApi';
import { resetPasswordUrl } from '@/routes';
import { useIsLoggedIn } from '@/utils/auth';
import { DisableFormikButton } from '@/utils/forms';
import { useErrorParam } from '@/utils/hooks';
import { setReturnToStorage, useReturnTo } from '@/utils/returnTo';
import { useTargetProperty } from '@/utils/targetProperty';

const LoginSchema = Yup.object().shape({
  id: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
  remember: Yup.boolean(),
});

const initialValues = {
  id: '',
  password: '',
  remember: true,
};

const isSSOError = (errMessage: string | undefined): boolean => {
  return errMessage?.toLowerCase() === 'sso required';
};

const defaultErrorTitle = 'Something Went Wrong';

const SSOSIgnIn: React.FC = () => {
  const router = useRouter();

  return (
    <>
      <FormDivider />

      <AuthBigButton
        btnType="blackOutline"
        bold={true}
        width="100%"
        onClick={() => {
          router.push('/loginSSO');
        }}
      >
        Sign in with SSO
      </AuthBigButton>

      <Paragraph textAlign="center" fontSize="14px" pt="15px" color={COLORS.Neutral[600]}>
        Your organization may require you to sign in using SSO.
      </Paragraph>
      <Paragraph textAlign="center" fontSize="14px" pt="2px" color={COLORS.Neutral[600]}>
        Please contact your admin if you need access.
      </Paragraph>
    </>
  );
};

const OauthSignIn: React.FC<{ returnTo: string | undefined | null }> = ({ returnTo }) => {
  const returnToQP = returnTo ? `&returnTo=${encodeURIComponent(returnTo)}` : '';

  const signInWithGithub = () => {
    setReturnToStorage(null);
    window.location.href = `${CONFIG_API_URL}/v1/login/oauth?flow=login&provider=github${returnToQP}`;
  };

  const signInWithGoogle = () => {
    setReturnToStorage(null);
    window.location.href = `${CONFIG_API_URL}/v1/login/oauth?flow=login&provider=google${returnToQP}`;
  };

  return (
    <Box mb="15px">
      <Flex alignItems="center" mt="15px">
        <AuthBigButton btnType="blackOutline" bold={true} width="100%" onClick={signInWithGithub} mr="10px">
          Sign in with GitHub
        </AuthBigButton>
        <AuthBigButton btnType="blackOutline" bold={true} width="100%" onClick={signInWithGoogle}>
          Sign in with Google
        </AuthBigButton>
      </Flex>
      <FormDivider text="or" />
    </Box>
  );
};

const Login: React.FC = () => {
  const [returnTo, performReturnTo] = useReturnTo();
  useTargetProperty();
  const [oauthError] = useErrorParam();
  const [error, setError] = useState<api.ApiError>();
  const [errorTitle, setErrorTitle] = useState(defaultErrorTitle);
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useIsLoggedIn();

  useEffect(() => {
    if (isLoggedIn) {
      performReturnTo();
    }
  }, [isLoggedIn, performReturnTo]);

  const handleLogin = async (values: LoginValues) => {
    setLoading(true);
    setError(undefined);

    try {
      await api.login(values);
      setIsLoggedIn(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setLoading(false);

      if (isSSOError(err?.response?.data?.message)) {
        setErrorTitle('SSO sign in required');
        setError({
          message: 'The organization that manages this account requires SSO to sign in. Please sign in with SSO below.',
        });
      } else {
        setErrorTitle(defaultErrorTitle);
        setError(err?.response?.data || err);
      }
    }
  };

  const displayedErrorMessage = error?.message || oauthError;

  return (
    <AuthLayout>
      <PageTitle title="Upbound Login" />
      <Flex flexDirection="column" alignItems="center" mb="20px">
        <AuthMainHeader>Sign In to Upbound</AuthMainHeader>
      </Flex>

      <Box pt="10px">
        {!!displayedErrorMessage && (
          <DialogBox title={errorTitle} msg={displayedErrorMessage} type={dialogConstants.DIALOG_ERROR} />
        )}
      </Box>

      {!CONFIG_ENTERPRISE_ENABLED && <OauthSignIn returnTo={returnTo} />}
      <Box pt="10px">
        <Formik
          initialValues={initialValues}
          onSubmit={handleLogin}
          validationSchema={LoginSchema}
          validateOnBlur={true}
          validateOnChange={true}
        >
          {formik => (
            <Form>
              <Box mb="15px">
                <Field
                  name="id"
                  label="Email"
                  placeholder="Email"
                  component={AuthFormInput}
                  errors={!!formik.touched.id && formik.errors.id}
                  trim={true}
                />
              </Box>
              <Box>
                <Field
                  name="password"
                  type="password"
                  label="Password"
                  placeholder="Password"
                  component={AuthFormInput}
                  errors={!!formik.touched.password && formik.errors.password}
                />
              </Box>
              <AuthStyledButton
                mt="22px"
                type="submit"
                btnType="redesignPurple"
                full={true}
                loading={loading}
                disabled={DisableFormikButton(formik, true, loading)}
                bold={true}
                id="auth_button-login"
              >
                Sign In
              </AuthStyledButton>
              <Flex my="15px" justifyContent="center">
                <Paragraph color={COLORS.Purple[600]} lineHeight="1.43">
                  <Link href={resetPasswordUrl()}>Forgot password?</Link>
                </Paragraph>
              </Flex>
            </Form>
          )}
        </Formik>
      </Box>

      <SSOSIgnIn />
    </AuthLayout>
  );
};

export default Login;
