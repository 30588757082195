import React from 'react';

import { COLORS } from '@/constants/colors';
import { Box, Flex } from '@/elements/Div';
import { Paragraph } from '@/elements/Paragraph';

const FormDivider: React.FC<{ text?: string }> = ({ text }) => {
  if (text) {
    return (
      <Flex alignItems="center" mt="24px">
        <Box borderTop={`solid 1px ${COLORS.Neutral[100]}`} height="1px" width="100%" flex="1" mt="1px" />
        <Paragraph textAlign="center" fontSize="12px" px="25px" color={COLORS.Neutral[600]}>
          {text}
        </Paragraph>
        <Box borderTop={`solid 1px ${COLORS.Neutral[100]}`} height="1px" width="100%" flex="1" mt="1px" />
      </Flex>
    );
  }

  return (
    <Flex alignItems="center" my="20px">
      <Box borderTop={`solid 1px ${COLORS.Neutral[100]}`} height="1px" width="100%" flex="1" />
    </Flex>
  );
};

export default FormDivider;
